@import '../scss/variables.scss';

// Title
%h1 {
  font-size: 1.5rem; // 4rem;
  line-height: 2rem; // 4rem;
  text-rendering: optimizeSpeed;
}

%h2 {
  font-size: 1.3rem; // 3rem;
  line-height: 1.5rem; // 2.8rem;
  text-rendering: optimizeSpeed;
}

%h3 {
  font-size: 1.2rem; // 2rem;
  line-height: 1.5rem; // 1.85rem;
  text-rendering: optimizeSpeed;
}

%i-title {
  font-family: $font-stack-highlight;
  font-size: 1.5rem;
  line-height: 1.15em;
  color: $color-primary;
  letter-spacing: $space-lg;
  font-weight: 400;
  text-rendering: optimizeSpeed;

  @media (max-width: $sm) {
    font-size: 1.1rem;
  }
}

%i-subtitle {
  font-family: $font-stack-highlight;
  font-size: 1rem;
  line-height: 1.25em;
  text-rendering: optimizeSpeed;
}

// Text
%i-text {
  font-size: 0.8125rem;
  line-height: 1.15rem;
  letter-spacing: $space-sm !important;
  text-rendering: optimizeSpeed;
}

%i-text-sm {
  font-size: 0.6rem;
  line-height: 1.25em;
  font-weight: 500;
  letter-spacing: initial;
  color: $color-secondary;
  text-rendering: optimizeSpeed;
}

%i-text-md {
  font-family: $font-stack-highlight;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 400;
  text-rendering: optimizeSpeed;
}

%i-main-call {
  font-family: $font-stack-serif;
  color: $color-text-menu;
  font-size: 3.6rem;
  line-height: 3.55rem;
  min-height: 3.6rem;
  text-rendering: optimizeSpeed;

  @media (max-width: $lg) {
    font-size: 3.1rem;
    line-height: 3.25rem;
  }

  @media (max-width: $md) {
    font-size: 2.8rem;
    line-height: 2.95rem;
  }

  @media (max-width: $xs) {
    font-size: 1.7rem;
    line-height: 1.85rem;
  }
}

// Button
%i-btn {
  background-color: $color-primary;
  color: $color-text-menu;
  border: 2px solid $color-primary;
  &.i-btn-inverted {
    background-color: transparent;
    color: $color-primary;
    border: $long-rule-primary;
  }

  font-size: 0.8rem;
  letter-spacing: $space-sm;
  padding: 0.6rem 2.2rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: initial;
  }

  &.active,
  &:hover {
    background-color: transparent;
    color: $color-primary;
    border: $long-rule-primary;
    &.i-btn-inverted {
      background-color: $color-primary;
      color: $color-text-menu;
      border: 2px solid $color-primary;
    }
  }
}

%i-btn-ok {
  background-color: transparent;
  color: $color-text-menu;
  font-size: 0.75rem;
  border-radius: 50px;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: initial;
  }

  &:hover {
    background-color: transparent;
  }
}

%i-box {
  // border-right: $thin-rule-primary;

  &:last-child {
    // &:nth-child(3n) {
    border-right: none !important;
  }

  @media (max-width: $lg) {
    &:nth-child(2n) {
      border-right: none !important;
    }

    &:nth-child(2n + 1) {
      // border-right: $thin-rule-primary !important;
    }
  }

  @media (max-width: $md) {
    &:nth-child(n) {
      border-right: none !important;
    }
  }

  @media (max-width: $sm) {
    &.i-box {
      border-right: initial;
    }
  }
}

%i-box-sm {
  height: 1.4rem;

  > div {
    width: 2.65rem;
  }

  // na regra anterior, na segunda posição adicionava barra dos dois lados
  // nesta regra atual, adiciona barra na esquerda, exceto na primeira
  & > :not(:first-child) {
    border-left: $thin-rule-dark;
  }

  .i-text-sm {
    line-height: 1.4em;
  }
}

// Search bar

%i-search-bar,
%i-form {
  select {
    color: $color-text-menu;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: $long-rule-light;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    width: 100%;

    option {
      background-color: $color-text-menu;
      color: $color-primary;
    }
  }

  input {
    @extend select;
    margin-top: -1px;

    &:focus {
      // outline: none;
      outline-color: #ccc;
    }

    &::placeholder {
      color: $color-text-menu;
    }
  }
}
