/* Reset */
* {
  &:focus {
    // box-shadow: none !important;
    // outline: none !important;
    outline-color: #ccc;
  }
}

html,
body,
form,
img,
div,
p,
h1,
h2,
h3,
h4,
ul,
a {
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}

ul {
  list-style-type: none;
}

a,
a:hover {
  text-decoration: none !important;
  // outline: none !important;
  outline-color: #ccc;
  cursor: pointer;
}

img {
  vertical-align: middle !important;
}

button {
  // outline: none !important;
  outline-color: #ccc;
  border: 0;

  &:focus {
    outline-color: #ccc;
  }
}

.btn {
  &:focus,
  &.focus {
    // box-shadow: none!important;
    // outline: none!important;
    outline-color: #ccc;
  }
}
