@import "assets/scss/reset";
@import "assets/scss/variables";
@import "assets/scss/extends";
@import "assets/scss/material";

/*Fonts*/
@font-face {
  font-family: "TradeGothicLTStd-Extended";
  font-display: swap;
  src: url("assets/fonts/TradeGothicLTStd-Extended.otf") format("opentype");
}
@font-face {
  font-family: "Akzidenz Grotesk Roman Bold";
  font-display: swap;
  src: url("assets/fonts/Akzidenz Grotesk Roman Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Akzidenz Grotesk Roman";
  font-display: swap;
  src: url("assets/fonts/Akzidenz Grotesk Roman.ttf") format("truetype");
}

/*Base-Styles*/
html,
body {
  background-color: $color-bg !important;
  font-family: $font-stack-primary !important;
  color: $color-primary !important;
  font-weight: 400 !important;
  letter-spacing: $space-sm;
  line-height: 1.15em;
}

::-webkit-scrollbar {
  width: 0.95rem;
  background-color: rgba($color-secondary, 0.15);

  &::-webkit-scrollbar-track {
    background-color: rgba($color-secondary, 0.1);
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba($color-secondary, 0.25);

  &:hover {
    background-color: $color-primary;
  }
}

.container {
  @media (min-width: 1365px) {
    max-width: 1200px;
  }
}

a,
button {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

/* MDI */
i.iconify,
svg.iconify {
  font-size: 1.2rem;
}

/*Titles*/
h2 {
  @extend %h2;
}

.i-title {
  @extend %i-title;
}

.i-subtitle {
  @extend %i-subtitle;
}

/*Texts*/
.i-text {
  @extend %i-text;
}

.i-text-sm {
  @extend %i-text-sm;
}

.i-text-md {
  @extend %i-text-md;
}

/*Menu Navbar*/
.i-menu {
  .navbar-brand {
    max-width: 14rem;
  }
}

/*Boxes*/
.i-box {
  @extend %i-box;
}

.i-box-sm {
  @extend %i-box-sm;
}

.border-gray {
  border-color: $gray;
}

/*Search Bar*/
.i-search-bar {
  /*Material*/
  .mat-pseudo-checkbox,
  .mat-checkbox-inoput,
  .mat-checkbox-inner-container,
  .mat-option-pseudo-checkbox,
  &.ng-start-inserted {
    border-radius: 0;
    border: 1px solid $color-primary !important;
    height: 1.1rem;
    width: 1.1rem;
    line-height: 1rem;

    &-checked {
      background-color: $color-primary !important;

      &::after {
        opacity: 0 !important;
      }
    }
  }
}

/*Buttons*/
.i-btn {
  @extend %i-btn;
}

.i-btn-cta {
  @extend %i-btn;
  color: $white;
  background-color: #0061df !important;

  &:hover {
    color: $smoke;
    border-color: #628eff;
    background-color: #628eff !important;
  }

  &:active {
    border-color: #0038ac;
    background-color: #0038ac !important;
    transform: translateY(1px);
  }
}

/*Modal*/
.modal {
  .modal-dialog {
    @media (max-width: $sm) {
      max-width: 100%;
    }

    .modal-content {
      border: none;
      border-radius: 0;
    }

    .modal-header {
      border-bottom: 0;

      .i-title {
        text-transform: uppercase;
      }

      .i-text-sm {
        letter-spacing: $space-sm;
        line-height: 1.5em;
      }
    }
  }
}

/* Bootstrap */
.btn-link {
  color: $gray;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $gray;
}

/* Sweetalert2 */
.swal2-popup {
  border: none !important;
  border-radius: 0 !important;

  .swal2-actions {
    button {
      @extend %i-btn;
      margin-bottom: 2rem;
      background-color: $color-text-menu;
      border-radius: 0;
      border: $long-rule-primary;
      color: $color-primary;

      &:hover {
        background-color: $color-primary;
        color: $color-text-menu;
      }
    }
  }

  .swal2-title {
    @extend %i-title;
    text-transform: uppercase;
    padding-top: 2rem;
  }

  .swal2-content {
    @extend %i-text;
    font-size: 1.1rem;
    padding: 2rem 1rem 1rem 1rem;
    font-weight: 500;
  }
}

/*Material*/
.mat-pseudo-checkbox,
.mat-checkbox-inoput,
.mat-checkbox-inner-container,
.mat-option-pseudo-checkbox,
.ng-start-inserted {
  border-radius: 0;
  border: 1px solid $color-primary;
  height: 1.15rem;
  width: 1.15rem;
  line-height: 1.05rem;

  &-checked {
    background-color: $color-primary !important;

    &::after {
      opacity: 0;
    }
  }
}

.mat-list-text,
.mat-option-text {
  @extend %i-text;
  font-family: $font-stack-primary !important;
  color: $color-primary;
  font-size: 0.8rem;
  padding-left: 0.75rem !important;
}

.mat-button {
  &:hover {
    // background: $color-text-menu !important;
    background: red !important;
    background-color: red !important;
  }

  .mat-button-wrapper {
    font-family: $font-stack-primary !important;
    height: 1.8rem !important;
    @extend %i-text;
    color: $color-primary;
    border-radius: 0;
  }

  &:hover {
    background: $color-text-menu !important;
    background-color: $color-text-menu !important;
  }
}

.mat-select-placeholder,
.mat-form-field-label-wrapper {
  color: $gray;

  .mat-form-field-label {
    color: inherit;
  }
}

.mat-form-field-should-float {
  .mat-form-field-label-wrapper {
    top: -1.5em;
  }
}

/*Effects*/
a.i-center-edge {
  position: relative;

  &:hover {
    color: currentColor;
  }

  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    background-color: currentColor;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

/*Efects ngx-hm-carousel (aniClass defaults is 'transition')*/
// .aniT {
//     transition: all 1s linear;
// }
.transition {
  transition: all 0.3s ease-in-out !important;
}

/*Pagination*/
.ngx-pagination {
  a,
  .ngx-pagination button {
    font-family: $font-stack-primary !important;
    color: $color-primary !important;

    &:hover {
      @extend .i-center-edge;
      background: $color-text-menu !important;
    }
  }

  .current {
    background-color: $color-text-menu !important;
    color: $color-secondary !important;
    font-weight: 500;
  }
}

/*Facebook Shimmer-Shine*/
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  width: 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  &-line {
    height: 1rem;
  }
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

/*Tooltip*/
.tooltip > .tooltip-inner {
  max-width: 200px;
  padding: 0.8rem 0.8rem;
  color: $color-primary;
  text-align: left;
  background-color: #fff;
  border-radius: 0.25rem;
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.4);
}
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
}

/*Modal de bairro*/
#neighborModal {
  .modal-body {
    width: 100%; // para a barra de rolagem ficar no lugar certo!
    height: 60vh;
    overflow-y: auto;
  }
}

/*Remover setas do type=number*/
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Proporção padrão para o logo_cinza.svg */
img[src$="logo_cinza.svg"],
img[src$="logo_branco.svg"] {
  aspect-ratio: auto 393.333 / 54.333 !important;
}
