@use '@angular/material' as mat;
@import '_variables.scss';
@import '_extends.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

/*General-Theme*/
$mat-private: (
  50: $color-primary,
  100: $color-primary,
  200: $color-primary,
  300: $color-primary,
  400: $color-primary,
  500: $color-primary,
  600: $color-primary,
  700: $color-primary,
  800: $color-primary,
  900: $color-primary,
  A100: $color-primary,
  A200: $color-primary,
  A400: $color-primary,
  A700: $color-primary,
  contrast: (
    50: $color-primary,
    100: $color-primary,
    200: $color-primary,
    300: $color-primary,
    400: $color-primary,
    500: $color-primary,
    600: $color-primary,
    700: $color-primary,
    800: $color-primary,
    900: $color-primary,
    A100: $color-primary,
    A200: $color-primary,
    A400: $color-primary,
    A700: $color-primary,
  ),
);
$private-app-primary: mat.define-palette($mat-private);
$private-app-accent: mat.define-palette(mat.$teal-palette);
$private-app-warn: mat.define-palette(mat.$red-palette);
$private-app-theme: mat.define-light-theme($private-app-primary, $private-app-accent, $private-app-warn);
@include mat.all-legacy-component-themes($private-app-theme);

.mat-pseudo-checkbox,
.mat-option-pseudo-checkbox,
.mat-checkbox-frame,
.mat-checkbox-inner-container {
  font-family: $font-stack-primary;
  border-radius: 0 !important;
  border: 1px solid $color-primary !important;
  height: 1.15rem !important;
  width: 1.15rem !important;

  &-checked {
    background-color: $color-primary !important;

    &::after {
      opacity: 0 !important;
    }
  }
}
.mat-checkbox-layout {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.mat-checkbox-label {
  font-family: $font-stack-primary;
  color: currentColor;
  padding-left: 0.25rem;
}

.mat-checkbox-frame {
  border: 0 !important;
}

.mat-checkbox-background {
  .mat-checkbox-checkmark-path {
    display: none;
  }
}

// Checkbox color
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-primary !important;
  border-radius: 0px !important;
}

/*Contact*/
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-input-element {
  color: $color-primary !important;
  font-family: $font-stack-primary !important;
  letter-spacing: $space-md;
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: $color-secondary;
    font-family: $font-stack-primary !important;
    letter-spacing: $space-sm;

    &.mat-accent,
    .mat-form-field-required-marker {
      color: $color-secondary;
    }
  }

  .mat-form-field-ripple,
  .mat-form-field-ripple.mat-accent {
    background-color: $color-primary;
  }
}

.mat-form-field-infix {
  @extend %i-text;
  color: $color-primary;
  font-family: $font-stack-primary !important;
  letter-spacing: $space-sm;
}

.mat-error,
mat-error.mat-error.ng-star-inserted {
  font-size: 0.53rem;
  letter-spacing: $space-sm;
  color: $color-warn;
}

.mat-select-trigger {
  height: 1.7rem;
}

.mat-select-value {
  color: $color-primary;
  font-family: $font-stack-primary;
}

.mat-select-arrow-wrapper {
  vertical-align: initial !important;
}

/*Intern-Search*/
.mat-list-item-content {
  padding: 0 !important;
  height: 0;
}

.mat-list .mat-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-item,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
  display: block;
  height: 1.8rem !important;
  font-family: $font-stack-primary !important;

  &:hover {
    background: $color-text-menu !important;
  }
}

.mat-menu-panel {
  .i-btn-select.mat-menu-item {
    font-family: $font-stack-primary !important;
    height: 1.8rem !important;
    @extend %i-text;
    color: $color-primary;
  }
}

button.mat-button {
  border-radius: 0 !important;

  &:hover {
    background-color: rgba($color-primary, 0.1) !important;
    background: rgba($color-primary, 0.1) !important;
  }

  .mat-button-wrapper {
    font-family: $font-stack-primary !important;
    height: 1.8rem !important;
    @extend %i-text;
    color: $color-primary;
    border-radius: 0;
  }
}

/* FILLLLL */
.mat-form-field-appearance-fill {
  // ajuste na seta, estava altas
  .mat-select-arrow-wrapper {
    transform: translateY(-15%) !important;
  }

  // ajuste nos labels, estavam altos
  .mat-form-field-label {
    margin-top: 0;
  }
}
