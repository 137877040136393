/* Descriptive colors*/
$black: #222222;
$white: #ffffff;
$smoke: #eef0f1;
$gray: #778594;
$orange: #e85600;
$whatsapp: #01e675;
/*Functional colors*/
$color-primary: $gray;
$color-secondary: $black;
$color-text-menu: $white;
$color-warn: $orange;
// Backgrounds
$color-bg: $white;
$color-bg-secondary: $gray;
$color-bg-dark: $black;
// Borders
$long-rule-light: 2px solid rgba($white, 0.35);
$solid-rule-primary: 1px solid $color-primary;
$thin-rule-primary: 1px solid rgba($color-primary, 0.35);
$long-rule-primary: 2px solid rgba($color-primary, 0.45);
$thin-rule-dark: 1px solid rgba($color-secondary, 0.35);
// Fonts
$font-stack-primary: "Akzidenz Grotesk Roman", Arial, sans-serif;
$font-stack-secondary: Helvetica, Arial, sans-serif;
$font-stack-highlight: "TradeGothicLTStd-Extended", Helvetica, Arial, sans-serif;
$font-stack-serif: "Times New Roman", serif;
// Letter Spacings
$space-xs: 0.015rem;
$space-sm: 0.085rem;
$space-md: 0.2rem;
// $space-lg: 0.7rem;
$space-lg: 0.55ch;
/*Breakpoints*/
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
